import Vue from 'vue'
import router from '@/router/index' // 해당되는 router 폴더 지정
import Vuex from 'vuex'
import '@/utils/commonLoad'
import _ from 'lodash'
import store from './store'
import global from '@/common/global' // 전역변수 사용하기 위해 import
import i18n from './locales'

Vue.use(Vuex)

/* eslint-disable */
new Vue({
  el: '#app',
  i18n,
  router,
  store
})
