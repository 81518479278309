import Vue from 'vue'
import VueRouter from 'vue-router'
import { $utils } from '@/common/helper'
import routes from './route'

Vue.use(VueRouter)

window.dataLayer = window.dataLayer || []
// dataLayer.push({
//   event: 'ecPageinfo',
//   behavior_country: $utils.getCountryCode(),
//   pageFullUrl: window.location.href
// })

const router = $utils.createRouter(routes, '/index')

export default router
