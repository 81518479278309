export default {
  US: {
    main:{
      text:{
        our_prd:"Our products",
        our_prd_text:" Incorporating world-recognized design and technology into the products,\n" +
          "            <br>\n" +
          "            LX Hausys provides the perfect selection for both commercial and residential spaces.",
        our_hflor_text: "A Perfect Flooring Selection <br/> Engineered with Precision",
        our_viatera_text: "Inspired by the Moment of Life. <br/> Inspired by Nature.",
        our_himacs_text: "The New Generation of Design <br/> Inspiration",
        getInspired: "Get Inspired",
        featured: "FEATURED PRODUCTS",
        all: "All",
        hflor_text: "Resilient Flooring",
        viatera_text: "Quartz Surface",
        himacs_text: "Solid Surface",
        segment: "SEGMENTS",
        seg_com: "Commercial",
        seg_res: "Residential",
        hland: "HLAND",
        retail_bea: "(Beauty)",
        retail_fit: "(Fitness)",
        orderSample: "Order a Sample",
        document: "Documentation",
        care_main: "Care & Maintenance",
        warranty: "Warranty",
        where_to_buy: "Where to buy",
        contactus: "Contact us"
      },
      button:{
        learnMore: "Learn more",
        seeAll: "See All",
      }
    }
  },

  GL: {
    main:{
      text:{
        our_prd:"Our products",
        our_prd_text:" Incorporating world-recognized design and technology into the products,\n" +
          "            <br>\n" +
          "            LX Hausys provides the perfect selection for both commercial and residential spaces.",
        our_hflor_text: "A Perfect Flooring Selection <br/> Engineered with Precision",
        our_viatera_text: "Inspired by the Moment of Life. <br/> Inspired by Nature.",
        our_himacs_text: "The New Generation of Design <br/> Inspiration",
        getInspired: "Get Inspired",
        featured: "FEATURED PRODUCTS",
        all: "All",
        hflor_text: "Resilient Flooring",
        viatera_text: "Quartz Surface",
        himacs_text: "Solid Surface",
        segment: "SEGMENTS",
        seg_com: "Commercial",
        seg_res: "Residential",
        hland: "HLAND",
        retail_bea: "(Beauty)",
        retail_fit: "(Fitness)",
        orderSample: "Order a Sample",
        document: "Documentation",
        care_main: "Care & Maintenance",
        warranty: "Warranty",
        where_to_buy: "Where to buy",
        contactus: "Contact us"
      },
      button:{
        learnMore: "Learn more",
        seeAll: "See All",
      }
    }
  },

  EN: {
    main:{
      text:{
        our_prd:"Our products",
        our_prd_text:" Incorporating world-recognized design and technology into the products,\n" +
          "            <br>\n" +
          "            LX Hausys provides the perfect selection for both commercial and residential spaces.",
        our_hflor_text: "A Perfect Flooring Selection <br/> Engineered with Precision",
        our_viatera_text: "Inspired by the Moment of Life. <br/> Inspired by Nature.",
        our_himacs_text: "The New Generation of Design <br/> Inspiration",
        getInspired: "Get Inspired",
        featured: "FEATURED PRODUCTS",
        all: "All",
        hflor_text: "Resilient Flooring",
        viatera_text: "Quartz Surface",
        himacs_text: "Solid Surface",
        segment: "SEGMENTS",
        seg_com: "Commercial",
        seg_res: "Residential",
        hland: "HLAND",
        retail_bea: "(Beauty)",
        retail_fit: "(Fitness)",
        orderSample: "Order a Sample",
        document: "Documentation",
        care_main: "Care & Maintenance",
        warranty: "Warranty",
        where_to_buy: "Where to buy",
        contactus: "Contact us",
        showroom: "Virtual Showroom",
        salesRep: "Find a sales representative",
      },
      button:{
        learnMore: "Learn more",
        seeAll: "See All",
      }
    }
  },

  DE: {
    main:{
      text:{
        our_prd:"Unsere Produkte",
        our_prd_text:" Durch die Integration von weltweit renommiertem Design und Technologie in seine Produkte bietet\n" +
          "            <br>\n" +
          "            LX Hausys die perfekte Auswahl für öffentliche, gewerbliche und private Räume.",
        our_hflor_text: "Eine perfekte Palette an Bodenbelägen,<br/> die mit Präzision entwickelt wurde",
        our_viatera_text: "Inspired by the Moment of Life. <br/> Inspired by Nature.",
        our_himacs_text: "Die neue Generation <br/> Designinspiration",
        getInspired: "Lassen Sie sich inspirieren",
        featured: "Ausgewählte Produkte",
        all: "Alle",
        hflor_text: "Widerstandsfähiger Bodenbelag",
        viatera_text: "Quartz Surface",
        himacs_text: "Feste Oberfläche",
        segment: "SEGMENTE",
        seg_com: "Kommerzielles",
        seg_res: "Wohnbereich",
        hland: "HLAND",
        retail_bea: "(Beauty)",
        retail_fit: "(Fitness)",
        orderSample: "Order a Sample",
        document: "Dokumentation",
        care_main: "Pflege und Instandhaltung",
        warranty: "Garantie",
        where_to_buy: "Bezugsquellen",
        contactus: "Kontaktieren Sie uns",
        showroom: "Virtueller Ausstellungsraum",
        salesRep: "Finden Sie einen Vertriebsmitarbeiter",
      },
      button:{
        learnMore: "Weitere Informationen",
        seeAll: "Alle anzeigen",
      }
    }
  },

  FR: {
    main:{
      text:{
        our_prd:"Nos produits",
        our_prd_text:" En intégrant un design et une technologie reconnus dans le monde entier à ses produits,\n" +
          "            <br>\n" +
          "            LX Hausys propose une sélection parfaite pour les espaces publics, commerciaux et résidentiels.",
        our_hflor_text: "Un choix parfait de revêtements <br/> de sol conçus avec précision",
        our_viatera_text: "Inspired by the Moment of Life. <br/> Inspired by Nature.",
        our_himacs_text: "L'inspiration nouvelle <br/> génération en matière de design",
        getInspired: "Inspirations",
        featured: "Produits en vedette",
        all: "Toute",
        hflor_text: "Revêtements de sol souples",
        viatera_text: "Quartz Surface",
        himacs_text: "Solid Surface",
        segment: "Espaces",
        seg_com: "commerciaux",
        seg_res: "Résidentiel",
        hland: "HLAND",
        retail_bea: "(Beauty)",
        retail_fit: "(Fitness)",
        orderSample: "Order a Sample",
        document: "Entretien et utilisation",
        care_main: "Entretien et utilisation",
        warranty: "Garantie",
        where_to_buy: "Où acheter",
        contactus: "Contactez-nous",
        showroom: "Showroom virtuel",
        salesRep: "Trouver un représentant",
      },
      button:{
        learnMore: "En savoir plus",
        seeAll: "Voir tout",
      }
    }
  },

  IT: {
    main:{
      text:{
        our_prd:"I nostri prodotti",
        our_prd_text:"Incorporando nei prodotti un design e una tecnologia riconosciuti a livello mondiale,\n" +
          "            <br>\n" +
          "            LX Hausys offre la selezione perfetta per spazi pubblici, commerciali e residenziali.",
        our_hflor_text: "Una perfetta selezione di pavimenti,<br/> progettata con precisione",
        our_viatera_text: "Inspired by the Moment of Life. <br/> Inspired by Nature.",
        our_himacs_text: "Ispirazione per una nuova <br/> generazione di design",
        getInspired: "Lasciati ispirare",
        featured: "Progetti in evidenza",
        all: "Tutte",
        hflor_text: "Pavimentazione resistente",
        viatera_text: "Quartz Surface",
        himacs_text: "Solid Surface",
        segment: "SEGMENTI",
        seg_com: "commerciale",
        seg_res: "Settore Residenzale",
        hland: "HLAND",
        retail_bea: "(Beauty)",
        retail_fit: "(Fitness)",
        orderSample: "Order a Sample",
        document: "Documentazione",
        care_main: "Cura & Manutenzione",
        warranty: "Garanzia",
        where_to_buy: "Rete di rivenditori / distributori",
        contactus: "Contattaci",
        showroom: "Showroom virtuale",
        salesRep: "Trova un rappresentante vendite",
      },
      button:{
        learnMore: "Scopri di più",
        seeAll: "Vedi tutto",
      }
    }
  },

  ES: {
    main:{
      text:{
        our_prd:"Nuestros productos",
        our_prd_text:" LX Hausys incorpora diseños y tecnologías de prestigio mundial en sus productos,\n" +
          "            <br>\n" +
          "            por lo que ofrece la selección perfecta para espacios públicos, comerciales y residenciales.",
        our_hflor_text: "Una selección perfecta de suelos <br/> diseñados con precisión",
        our_viatera_text: "Inspired by the Moment of Life. <br/> Inspired by Nature.",
        our_himacs_text: "La Nueva Generación de <br/> Inspiración para el diseño",
        getInspired: "Inspírate",
        featured: "Productos destacados",
        all: "Todos",
        hflor_text: "Suelo resistente",
        viatera_text: "Quartz Surface",
        himacs_text: "Piedra acrílica",
        segment: "SEGMENTOS",
        seg_com: "comercial",
        seg_res: "Residencial",
        hland: "HLAND",
        retail_bea: "(Beauty)",
        retail_fit: "(Fitness)",
        orderSample: "Order a Sample",
        document: "Información",
        care_main: "Mantenimiento y cuidados",
        warranty: "Garantía",
        where_to_buy: "Dónde comprar",
        contactus: "Contacto",
        showroom: "Showroom virtual",
        salesRep: "Contactar con un comercial",
      },
      button:{
        learnMore: "Más información",
        seeAll: "Ver todo",
      }
    }
  },

  UK: {
    main:{
      text:{
        our_prd:"Our products",
        our_prd_text:" Incorporating world-recognized design and technology into the products,\n" +
          "            <br>\n" +
          "            LX Hausys provides the perfect selection for both commercial and residential spaces.",
        our_hflor_text: "A Perfect Flooring Selection <br/> Engineered with Precision",
        our_viatera_text: "Inspired by the Moment of Life. <br/> Inspired by Nature.",
        our_himacs_text: "The New Generation of Design <br/> Inspiration",
        getInspired: "Get Inspired",
        featured: "FEATURED PRODUCTS",
        all: "All",
        hflor_text: "Resilient Flooring",
        viatera_text: "Quartz Surface",
        himacs_text: "Solid Surface",
        segment: "SEGMENTS",
        seg_com: "Commercial",
        seg_res: "Residential",
        hland: "HLAND",
        retail_bea: "(Beauty)",
        retail_fit: "(Fitness)",
        orderSample: "Order a Sample",
        document: "Documentation",
        care_main: "Care & Maintenance",
        warranty: "Warranty",
        where_to_buy: "Where to buy",
        contactus: "Contact us",
        showroom: "Virtual Showroom",
        salesRep: "Find a sales representative",
      },
      button:{
        learnMore: "Learn more",
        seeAll: "See All",
      }
    }
  }
}
