const routes = [
  {
    path: '/error/notFound',
    name: 'notFound',
    component: () => import('@/views/index/error/notFound.vue'),
    meta: { }
  },
  {
    path: '*',
    redirect: '/error/notFound'
  },
  {
    path: '/',
    name: 'app',
    component: () => import('@/views/index/app.vue'),
    meta: { code: '', gl: true, us: true }
  }
]

export default routes
